import Hashids from 'hashids';

export default {
  methods: {
    getUserID(hash) {
      /* const inputHashids = new Hashids('oMKeFPg0GHBU', 32, '0123456789abcdef'); */
      // eslint-disable-next-line
      var a=['b01LZUZQZzBHSEJV','MDEyMzQ1Njc4OWFiY2RlZg=='];(function(c,d){var e=function(f){while(--f){c['push'](c['shift']());}};e(++d);}(a,0x134));var b=function(c,d){c=c-0x0;var e=a[c];if(b['WODJgW']===undefined){(function(){var f;try{var g=Function('return\x20(function()\x20'+'{}.constructor(\x22return\x20this\x22)(\x20)'+');');f=g();}catch(h){f=window;}var i='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';f['atob']||(f['atob']=function(j){var k=String(j)['replace'](/=+$/,'');for(var l=0x0,m,n,o=0x0,p='';n=k['charAt'](o++);~n&&(m=l%0x4?m*0x40+n:n,l++%0x4)?p+=String['fromCharCode'](0xff&m>>(-0x2*l&0x6)):0x0){n=i['indexOf'](n);}return p;});}());b['ZZjeRw']=function(q){var r=atob(q);var s=[];for(var t=0x0,u=r['length'];t<u;t++){s+='%'+('00'+r['charCodeAt'](t)['toString'](0x10))['slice'](-0x2);}return decodeURIComponent(s);};b['AxVcar']={};b['WODJgW']=!![];}var v=b['AxVcar'][c];if(v===undefined){e=b['ZZjeRw'](e);b['AxVcar'][c]=e;}else{e=v;}return e;};const inputHashids=new Hashids(b('0x0'),0x20,b('0x1'));
      const output = inputHashids.decode(hash);
      return output[0];
    },
    getHash(id) {
      /* const outputHashids = new Hashids('X5lM3VPyBvm', 32, '0123456789abcdef'); */
      // eslint-disable-next-line
      var a=['MDEyMzQ1Njc4OWFiY2RlZg==','WDVsTTNWUHlCdm0='];(function(c,d){var e=function(f){while(--f){c['push'](c['shift']());}};e(++d);}(a,0xc9));var b=function(c,d){c=c-0x0;var e=a[c];if(b['jetyiw']===undefined){(function(){var f;try{var g=Function('return\x20(function()\x20'+'{}.constructor(\x22return\x20this\x22)(\x20)'+');');f=g();}catch(h){f=window;}var i='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';f['atob']||(f['atob']=function(j){var k=String(j)['replace'](/=+$/,'');for(var l=0x0,m,n,o=0x0,p='';n=k['charAt'](o++);~n&&(m=l%0x4?m*0x40+n:n,l++%0x4)?p+=String['fromCharCode'](0xff&m>>(-0x2*l&0x6)):0x0){n=i['indexOf'](n);}return p;});}());b['MScJcV']=function(q){var r=atob(q);var s=[];for(var t=0x0,u=r['length'];t<u;t++){s+='%'+('00'+r['charCodeAt'](t)['toString'](0x10))['slice'](-0x2);}return decodeURIComponent(s);};b['hScPvx']={};b['jetyiw']=!![];}var v=b['hScPvx'][c];if(v===undefined){e=b['MScJcV'](e);b['hScPvx'][c]=e;}else{e=v;}return e;};const outputHashids=new Hashids(b('0x0'),0x20,b('0x1'));
      return outputHashids.encode([id, Date.now()]);
    },
    makeToast(options) {
      const defaultOptions = {
        id: '',
        title: '警告！',
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
        solid: true,
        noAutoHide: true,
        appendToast: true,
        noFade: false,
      };
      const mergerdOptions = { ...defaultOptions, ...options };

      this.$bvToast.toast(mergerdOptions.content, {
        id: mergerdOptions.id,
        title: mergerdOptions.title,
        variant: mergerdOptions.variant,
        toaster: mergerdOptions.toaster,
        solid: mergerdOptions.solid,
        noAutoHide: mergerdOptions.noAutoHide,
        appendToast: mergerdOptions.appendToast,
        noFade: mergerdOptions.noFade,
      });
    },
    apiKey() {
      const hash = (new URL(window.location.href)).searchParams.get('key');
      return this.getHash(this.getUserID(hash));
    },
  },
  computed: {
    userId() {
      const hash = (new URL(window.location.href)).searchParams.get('key');
      return this.getUserID(hash);
    },
  },
};
