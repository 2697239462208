<template>
  <b-modal
    scrollable
    centered
    ok-only
    id="train-modal"
    title="訓練模型"
    ok-title="開始訓練"
    v-model="modal.visible"
    @ok="train">
    <b-alert variant="info" show>
      <fa-icon icon="info-circle"></fa-icon>
      即將訓練模型 <strong>{{ model ? model.name : '' }}</strong>
    </b-alert>
    <b-form-group label="Epoch">
      <b-form-radio-group
        v-model="form.selected"
        :options="form.options">
      </b-form-radio-group>
    </b-form-group>
  </b-modal>
</template>

<script>
import axios from 'axios';
import variableMixin from '../lib/variableMixin';
import generalMixin from '../lib/generalMixin';

export default {
  name: 'TrainModal',
  mixins: [variableMixin, generalMixin],
  props: {
    model: Object,
  },
  data() {
    return {
      modal: {
        visible: false,
      },
      form: {
        selected: '100',
        options: [
          { text: '100', value: '100' },
          { text: '300', value: '300' },
          { text: '1000', value: '1000' },
          { text: '3000', value: '3000' },
        ],
      }
    }
  },
  methods: {
    modalState(state) {
      this.modal.visible = state;
    },
    async train() {
      await axios.post(`${this.apiBaseUrl}/detector/model/${this.model.id}/train`, {
        totalEpochsAll: this.form.selected
      }, {
        params: {
          hashkey: this.apiKey()
        }
      });
    }
  },
}
</script>
