<template>
  
</template>

<script>
import io from 'socket.io-client';
import variableMixin from '../lib/variableMixin';
import generalMixin from '../lib/generalMixin';

export default {
  name: 'SocketIO',
  mixins: [variableMixin, generalMixin],
  data() {
    return {
      socket: null,
    }
  },
  created() {
    this.socket = io(this.socketUrl);

    this.socket.on('connect', () => {
      console.log('Authorizing...');
      this.socket.emit('authentication', { user: this.socketUser, pass: this.socketPass });
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from server.');
      this.$emit('disconnected');
    });

    this.socket.on('jobReport', data => {
      this.$emit(data.action || 'other', data);
    });

    this.socket.on('authenticated', () => {
      console.log('Connected to server.');
      this.$emit('connected');

      const hash = (new URL(location.href)).searchParams.get('key');

      this.socket.emit('registry', {
        role: 'user',
        userId: this.getUserID(hash),
      });

      this.socket.once('registry', (result) => {
        console.log('Successfully registered.');
      });
    });

  },
  breforeDestroy() {
    this.socket = null;
  }
}
</script>
