export default {
  data() {
    return {
      socketUser: 'client',
      socketPass: 'client',
    };
  },
  computed: {
    socketUrl() {
      if (window.location.host === '127.0.0.1:1234') {
        /* For local development */
        return 'http://192.168.0.80:3000';
      }
      return null;
    },
    apiBaseUrl() {
      if (window.location.host === '127.0.0.1:1234') {
        /* For local development */
        return 'http://192.168.0.80:3000/api';
      }
      return '/api';
    },
  },
};
