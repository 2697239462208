<template>
  <b-dropdown
    v-show="showCondition"
    :text="getDropdownConfig('text')"
    :variant="getDropdownConfig('variant')"
    :toggle-class="getDropdownConfig('toggle-class')"
    :no-caret="getDropdownConfig('no-caret')"
    :style="getDropdownConfig('style')"
    :class="getDropdownConfig('class')"
    ref="dropdown"
    @shown="$emit('shown')"
    @hide="visibility = false">
    <b-dropdown-item
      v-show="dropdownItemState('addDataset')"
      @click="openModal('dataset-modal')">
      <fa-icon icon="plus" class="dropdown-icon"></fa-icon>
      加入資料集
    </b-dropdown-item>
    <b-dropdown-item
      v-show="dropdownItemState('identification')"
      @click="openModal('identification-modal')">
      <fa-icon icon="eye" class="dropdown-icon"></fa-icon> 辨識影像
    </b-dropdown-item>
    <b-dropdown-item
      v-show="dropdownItemState('train')"
      @click="openModal('train-modal')">
      <fa-icon icon="robot" class="dropdown-icon"></fa-icon>
      {{ ['done', 'error'].indexOf(model.state) > -1 ? '重新訓練': '訓練模型' }}
    </b-dropdown-item>
    <b-dropdown-divider v-show="dropdownItemState('stopTraining') || dropdownItemState('removeModel')"></b-dropdown-divider>
    <b-dropdown-item
      v-show="dropdownItemState('stopTraining')"
      @click="stopTrainingNotification">
      <span class="text-danger">
        <fa-icon icon="stop-circle" class="dropdown-icon"></fa-icon> 停止訓練
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      v-show="dropdownItemState('removeModel')"
      @click="deleteModelNotification">
      <span class="text-danger">
        <fa-icon icon="trash" class="dropdown-icon"></fa-icon> 刪除模型
      </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import axios from 'axios';
import variableMixin from '../lib/variableMixin';
import generalMixin from '../lib/generalMixin';

export default {
  mixins: [variableMixin, generalMixin],
  props: {
    mode: String,
    left: Number,
    top: Number,
    model: Object,
  },
  data() {
    return {
      axios: null,
      visibility: false,
    }
  },
  methods: {
    show() {
      this.visibility = true;
      this.$refs.dropdown.show();
    },
    dropdownItemState(item) {
      switch (item) {
        case 'addDataset':
          return this.model.owner === this.userId;
        case 'identification':
          return this.model.state === 'done';
        case 'train':
          return this.model.owner === this.userId &&
            this.model.dataset &&
            ['pending', 'running'].indexOf(this.model.state) === -1;
        case 'stopTraining':
          return this.model.owner === this.userId &&
            ['pending', 'running'].indexOf(this.model.state) > -1;
        case 'removeModel':
          return this.model.owner === this.userId;
      }
    },
    openModal(modalName) {
      this.$bvModal.show(modalName);
    },
    getDropdownConfig(configName) {
      switch (configName) {
        case 'text':
          return this.getTextConfig;
        case 'style':
          return this.getStyleConfig;
        case 'toggle-class':
          return this.getToggleClassConfig;
        case 'class':
          return this.getClassConfig;
        case 'no-caret':
          return this.getCaretConfig;
        case 'variant':
          return this.getVariantConfig;
      }
    },
    async stopTraining(id) {
      if (!id) {
        return;
      }
      await this.axios_.post(`/detector/model/${id}/train/terminate`);
      this.makeToast({
        title: '已停止！',
        content: '已停止模型訓練',
        variant: 'info',
        noAutoHide: false,
      });
      this.$emit('reload');
    },
    async deleteModel(id) {
      if (!id) {
        return;
      }
      await this.axios_.delete(`/detector/model/${id}`);
      this.makeToast({
        title: '完成！',
        content: '已刪除模型',
        variant: 'success',
        noAutoHide: false,
      });
      this.$emit('reload');
    },
    stopTrainingNotification() {
      if (!this.model) {
        return;
      }
      const h = this.$createElement;
      const toastId = Date.now().toString();
      const clickHandler = () => {
        this.stopTraining(this.model.id);
        this.$bvToast.hide(toastId);
      };
      const vNodesMsg = h(
        'div',
        {},
        [
          `確定要停止訓練模型 "${this.model.name}" 嗎？`,
          h('br'),
          h(
            'b-button', {
              props: { variant: 'danger', size: 'sm' },
              style: { 'margin': '5px 3px 0 0' },
              on: { click: clickHandler },
            },
            ['停止訓練']
          ),
          h(
            'b-button', {
              props: { variant: 'outline-danger', size: 'sm' },
              style: { 'margin-top': '5px' },
              on: { click: this.$bvToast.hide.bind(this.$bvToast, toastId) }
            },
            ['取消']
          ),
        ],
      );
      this.makeToast({
        id: toastId,
        title: '警告！',
        content: vNodesMsg,
        variant: 'danger',
        noFade: true
      });
    },
    deleteModelNotification() {
      if (!this.model) {
        return;
      }
      const h = this.$createElement;
      const toastId = Date.now().toString();
      const clickHandler = () => {
        this.deleteModel(this.model.id);
        this.$bvToast.hide(toastId);
      };
      const vNodesMsg = h(
        'div',
        {},
        [
          `確定要刪除模型 "${this.model.name}" 嗎？此動作無法復原`,
          h('br'),
          h(
            'b-button', {
              props: { variant: 'danger', size: 'sm' },
              style: { 'margin': '5px 3px 0 0' },
              on: { click: clickHandler },
            },
            ['確定刪除']
          ),
          h(
            'b-button', {
              props: { variant: 'outline-danger', size: 'sm' },
              style: { 'margin-top': '5px' },
              on: { click: this.$bvToast.hide.bind(this.$bvToast, toastId) }
            },
            ['取消']
          ),
        ],
      );
      this.makeToast({
        id: toastId,
        title: '警告！',
        content: vNodesMsg,
        variant: 'danger',
        noFade: true
      });
    },
  },
  computed: {
    getStyleConfig() {
      if (this.mode === 'context-menu') {
        return {
          top: 'calc(' + this.top + 'px - 4em)',
          left: 'calc(' + this.left + 'px)'
        }
      }
      if (this.mode === 'button') {
        return {};
      }
    },
    getToggleClassConfig() {
      if (this.mode === 'context-menu') {
        return 'text-decoration-none';
      }
      if (this.mode === 'button') {
        return '';
      }
    },
    getCaretConfig() {
      if (this.mode === 'context-menu') {
        return true;
      }
      if (this.mode === 'button') {
        return false;
      }
    },
    getVariantConfig() {
      if (this.mode === 'context-menu') {
        return 'link';
      }
      if (this.mode === 'button') {
        return 'info';
      }
    },
    getClassConfig() {
      if (this.mode === 'context-menu') {
        return 'absolute-position';
      }
      if (this.mode === 'button') {
        return '';
      }
    },
    showCondition() {
      if (this.mode === 'context-menu') {
        return this.visibility;
      }
      if (this.mode === 'button') {
        return true;
      }
    },
    getTextConfig() {
      if (this.mode === 'context-menu') {
        return '';
      }
      if (this.mode === 'button') {
        return '操作';
      }
    },
    axios_() {
      if (!this.axios) {
        this.axios = axios.create({
          baseURL: this.apiBaseUrl,
          params: {
            hashkey: this.apiKey()
          },
        });
      }
      return this.axios;
    },
    userId() {
      const hash = (new URL(window.location.href)).searchParams.get('key');
      return this.getUserID(hash);
    }
  },
}
</script>

<style scoped>
  .absolute-position {
    position: absolute !important;
  }
  .dropdown-icon {
    width: 18px;
    margin-right: 2px;
    opacity: .8;
  }
</style>
