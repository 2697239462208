import Vue from 'vue';
/* import VueKonva from 'vue-konva'; */
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faRobot,
  faEye,
  faLock,
  faUsers,
  faPlus,
  faStopCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App';

library.add(
  faTrash,
  faRobot,
  faEye,
  faLock,
  faUsers,
  faPlus,
  faStopCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
);

Vue.use(BootstrapVue);
/* Vue.use(VueKonva); */

Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
});
