<template>
  <div>
    <DropdownMenu
      mode="context-menu"
      ref="dropdown"
      :left="dropdown.left"
      :top="dropdown.top"
      :model="dropdown.model"
      @reload="loadModels"></DropdownMenu>

    <b-row class="mt-3" align-h="between">
      <b-col cols="12" md="3">
        <b-form-group :state="modelForm.state" :invalid-feedback="modelForm.feedback || '請輸入模型名稱'">
          <b-input-group v-if="modelForm.buttonClicked">
            <b-form-input
              placeholder="輸入模型名稱..."
              v-model="modelForm.name"
              :state="modelForm.state"
              @input="modelForm.state = null;"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="createModel">建立模型</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button variant="primary" v-if="!modelForm.buttonClicked" @click="modelForm.buttonClicked = true">
            <fa-icon icon="plus"></fa-icon> 新增模型
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="3">
        <div class="d-md-none mt-3"></div>
        <b-form-group>
          <b-input-group>
            <b-input v-model="table.filter" placeholder="搜尋..." />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-table
          hover
          fixed
          show-empty
          responsive
          stacked="md"
          primary-key="id"
          :items="table.items"
          :fields="table.fields"
          :filter="table.filter"
          :per-page="table.perPage"
          :filter-function="customFilter"
          :current-page="table.currentPage"
          :empty-text="table.strings.emptyText"
          :empty-filtered-text="table.strings.emptyFilteredText"
          @filtered="tableOnFiltered"
          @row-contextmenu="tableOnContextMenu"
          @selectstart="e => e.preventDefault()">

          <template slot="shareStatus" slot-scope="data">
            <span v-if="data.item.shared">
              <fa-icon icon="users"></fa-icon>
              {{ data.item.owner === userId ? '公開' : '他人' }}分享
            </span>
            <span class="text-muted" v-if="!data.item.shared">
              <fa-icon icon="lock" ></fa-icon>
              私人檔案
            </span>
            <b-link v-if="shareLinkState(data)" @click="sharingNotification(data.item.id, data.item.shared)">設定</b-link>
          </template>

          <template slot="avgLoss" slot-scope="data">
            {{ lossHandler(data) }}
          </template>

          <template slot="progress" slot-scope="data">
            <div v-if="data.item.state === 'pending'">
              <span class="text-muted">等待訓練中</span>
            </div>
            <div v-if="data.item.state === 'running'" class="w-50" style="margin: 0 auto;">
              <b-progress :max="100" height="2rem" variant="secondary" show-progress animated>
                <b-progress-bar :value="(progressInfo(data)).progress" style="line-height: .9rem;">
                  訓練中
                  <span>
                    {{ (progressInfo(data)).progress }} %
                  </span>
                </b-progress-bar>
              </b-progress>
            </div>
            <div v-if="data.item.state === 'error'">
              <span class="text-danger">
                <fa-icon icon="exclamation-triangle"></fa-icon>
                發生錯誤
              </span>
            </div>
            <div v-if="data.item.state === 'done'">已儲存</div>
            <div v-if="!data.item.state">
              <span class="text-muted">未訓練</span>
            </div>
          </template>

          <template slot="action" slot-scope="data">
            <DropdownMenu
              mode="button"
              :model="data.item"
              @reload="loadModels"
              @shown="dropdown.model = data.item"></DropdownMenu>
          </template>

        </b-table>
      </b-col>
      <b-toast id="disconnected-toast" title="失去連線" variant="danger" toaster="b-toaster-bottom-right" no-auto-hide>
        已從伺服器斷線，重新整理網頁以重新連線
      </b-toast>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" md="3" align-self="center">
        <b-pagination
          v-model="table.currentPage"
          :total-rows="table.totalRows"
          :per-page="table.perPage"
        ></b-pagination>
      </b-col>
    </b-row>

    <DatasetModal ref="datasetModal" :model="dropdown.model" @uploaded="loadModels"></DatasetModal>
    <IdentificationModal ref="identificationModal" :model="dropdown.model"></IdentificationModal>
    <TrainModal ref="trainModal" :model="dropdown.model"></TrainModal>
    <SocketIO @train="onTraining" @disconnected="disconnectedHandler" @connected="connectedHandler"></SocketIO>
  </div>
</template>

<script>
import axios from 'axios';
import generalMixin from '../lib/generalMixin';
import variableMinxin from '../lib/variableMixin';
import DatasetModal from './DatasetModal';
import IdentificationModal from './IdentificationModal';
import TrainModal from './TrainModel';
import DropdownMenu from './DropdownMenu';
import SocketIO from './socketIO';

export default {
  name: 'Model',
  components: {
    DatasetModal,
    IdentificationModal,
    TrainModal,
    DropdownMenu,
    SocketIO,
  },
  mixins: [generalMixin, variableMinxin],
  data() {
    return {
      axios: null,
      modelForm: {
        buttonClicked: false,
        name: '',
        state: null,
        feedback: ''
      },
      dropdown: {
        top: 0,
        left: 0,
        model: {},
      },
      modelMapping: [],
      table: {
        strings: {
          emptyFilteredText: '找不到指定紀錄',
          emptyText: '沒有任何紀錄可顯示',
        },
        items: [],
        fields: [
          { key: 'name', label: '名稱', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'avgLoss', label: '損失誤差', class: 'text-center' },
          { key: 'progress', label: '訓練進度', class: 'text-center'},
          { key: 'shareStatus', label: '分享狀態', class: 'text-center' },
          { key: 'action', label: '功能', class: 'text-center' },
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        filter: null,
        progress: {
          max: 100,
        },
      },
    };
  },
  methods: {
    async loadModels() {
      this.table.items = (await this.axios_(`/detector/models`)).data;
      this.modelMapping = this.table.items.map(i => i.id);
      this.table.totalRows = this.table.items.length;
      this.table.currentPage = 1;
    },
    tableOnFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length;
      this.table.currentPage = 1;
    },
    tableOnContextMenu (item, index, event) {
      event.preventDefault();
      this.dropdown.model = item;
      this.dropdown.left = event.x;
      this.dropdown.top = event.y;
      this.$refs.dropdown.show();
    },
    customFilter (data, filter) {
      if (data.name.indexOf(filter) > -1) {
        return true;
      }
      return false;
    },
    shareStatus (data) {
      const publicTemplate = { text: '公開分享', class: '', icon: 'users' };
      const privateTemplate = { text: '私人檔案', class: 'text-muted', icon: 'lock' };

      return data.item.shared ? publicTemplate : privateTemplate;
    },
    shareLinkState(data) {
      if (data.item.owner === this.userId && data.item.state === 'done') {
        return true;
      }
      return false;
    },
    lossHandler(data) {
      try {
        if (data.item.state === 'pending') {
          return '--';
        }
        if (data.item.trainedRecord.info.epochs.bestLoss) {
          return data.item.trainedRecord.info.epochs.bestLoss.loss;
        }
        return data.item.trainedRecord.info.epochs.avgLoss || '--';
      } catch (ex) {
        return '--';
      }
    },
    progressInfo (data) {
      switch (data.item.state) {
        case 'running':
          if (!data.item.trainedRecord.info) {
            return { progress: 0 };
          }
          const currentEpoch = data.item.trainedRecord.info.epochs.epoch;
          const totalEpoch = data.item.trainedRecord.info.epochs.total;
          const currentBatch = data.item.trainedRecord.info.batches.batch;
          const totalBatch = data.item.trainedRecord.info.batches.total;
          const progress = Math.round(((currentBatch + (currentEpoch * 100)) / (totalEpoch * 100)) * 100);

          return { progress };
        default:
          return '--';
      }
    },
    async connectedHandler() {
      await this.loadModels();
      this.$bvToast.hide('disconnected-toast');
    },
    disconnectedHandler() {
      this.$bvToast.show('disconnected-toast');
    },
    trainedNotification() {
      this.makeToast({
        title: '開始訓練！',
        content: '已開始模型訓練，進度請見列表',
        variant: 'info',
        noAutoHide: false,
      });
    },
    sharingNotification(id, shared) {
      const h = this.$createElement;
      const description = shared ? 
      '確定要取消分享此模型嗎？其他用戶將無法存取此模型' :
      '確定要公開分享此模型嗎？其他用戶將可以存取此模型';
      const buttonTitle = shared ? '取消分享' : '確定分享';
      const toastId = Date.now().toString();

      const clickHandler = () => {
        this.shareModel(id, shared);
        this.$bvToast.hide(toastId);
      };
      const vNodesMsg = h(
        'div',
        {},
        [
          description,
          h('br'),
          h(
            'b-button', {
              props: { variant: 'info', size: 'sm' },
              style: { 'margin': '5px 3px 0 0' },
              on: { click: clickHandler },
            },
            [buttonTitle]
          ),
          h(
            'b-button', {
              props: { variant: 'outline-info', size: 'sm' },
              style: { 'margin-top': '5px' },
              on: { click: this.$bvToast.hide.bind(this.$bvToast, toastId) }
            },
            ['取消']
          ),
        ],
      );
      this.makeToast({
        id: toastId,
        title: '設定分享模型狀態',
        content: vNodesMsg,
        variant: 'info',
        noFade: true
      });
    },
    async createModel() {
      if (this.modelForm.name.length > 0) {
        this.modelForm.state = null;
        try {
          await this.axios_.post(`/detector/model`, { modelName: this.modelForm.name });
          await this.loadModels();
        } catch (ex) {
          console.error(ex);
          this.modelForm.state = false;
          this.modelForm.feedback = '建立模型時發生錯誤，請檢查名稱是否重複';
          return;
        }
        this.modelForm.buttonClicked = !this.modelForm.buttonClicked;
        this.modelForm.name = '';
      } else {
        this.modelForm.feedback = '';
        this.modelForm.state = false;
      }
    },
    async shareModel(id, shared) {
      const toastContent = shared ? '已取消分享' : '已公開分享模型'
      await this.axios_.put(`/detector/model/${id}/shared/${!shared}`, null);
      await this.loadModels();
      this.makeToast({
        title: '完成！',
        content: toastContent,
        variant: 'success',
        noAutoHide: false,
      });
    },
    onTraining(data) {
      const index = this.modelMapping.indexOf(parseInt(data.modelId));

      if (index > -1) {
        this.table.items[index].trainedRecord = data;
        this.table.items[index].state = data.state;
      }
    },
    dropdownItemState(item, model) {
      if (!model) {
        model = this.dropdown.targetModel;
      }

      switch (item) {
        case 'addDataset':
          return model.owner !== this.userId;
        case 'identification':
          return model.state !== 'done';
        case 'train':
          return model.owner !== this.userId ||
            !model.dataset ||
            ['pending', 'running'].indexOf(model.state) > -1;
        case 'stopTraining':
          return model.owner === this.userId &&
            ['pending', 'running'].indexOf(model.state) > -1;
        case 'removeModel':
          return model.owner === this.userId;
      }
    },
  },
  computed: {
    axios_() {
      if (!this.axios) {
        this.axios = axios.create({
          baseURL: this.apiBaseUrl,
          params: {
            hashkey: this.apiKey()
          },
        });
      }
      return this.axios;
    },
  },
  async created() {
    if (!this.apiKey()) {
      this.makeToast({
        title: '發生錯誤！',
        content: '授權失敗，請確定網址是否無誤',
        variant: 'danger',
        noAutoHide: true,
      });
    }
  }
};
</script>
