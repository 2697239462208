<template>
  <b-container id="app">
    <div class="via">
      <a href="https://md.kingkit.codes/s/V-O_SbemP" target="_blank">
        標記工具
        <fa-icon icon="external-link-alt"></fa-icon>
      </a>
    </div>
    <b-row class="mt-5">
      <b-col>
        <Model/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import variableMixin from './lib/variableMixin.js';
import Model from './components/Model';

export default {
  name: 'App',
  mixins: [variableMixin],
  components: {
    Model,
  },
};
</script>

<style lang="scss" scoped>
  .container {
    max-width: 100%;
  }
  
  .via {
    position: fixed;
    top: .5em;
    right: 1.5em;
  }
</style>
