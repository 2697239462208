<template>
  <b-modal
    ok-only
    size="lg"
    title="辨識影像"
    ok-title="上傳並辨識"
    id="identification-modal"
    v-model="modal.visible"
    :visible="modal.visible"
    :busy="form.progress.loading"
    :no-close-on-esc="form.progress.loading"
    :no-close-on-backdrop="form.progress.loading"
    @ok="submit"
    @hidden="reset">
    <b-alert variant="info" show>
      <fa-icon icon="info-circle"></fa-icon>
      正在使用模型 <strong>{{ model ? model.name : '' }}</strong> 辨識影像
    </b-alert>
    <b-form @submit="submit">
      <b-form-group
        label="欲辨識之影像"
        description="僅接受 .jpg 及 .jpeg">
        <b-form-file
          v-model="form.file"
          placeholder=""
          browse-text="選擇檔案"
          drop-placeholder="拖放檔案到這裡"
          accept="image/jpeg,image/gif"
          required
          :disabled="form.progress.loading"
          @change="reset(true)">
        </b-form-file>
      </b-form-group>
    </b-form>
    <div class="mt-2">
      <b-progress
        :value="form.progress.value"
        variant="info"
        striped
        :animated="form.progress.animated"
        v-if="form.progress.loading"></b-progress>
      <b-img :src="form.image" v-if="form.image" style="max-width: 100%">
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import variableMixin from '../lib/variableMixin';
import generalMixin from '../lib/generalMixin';

export default {
  name: 'IdentificationModal',
  props: {
    model: Object,
  },
  mixins: [variableMixin, generalMixin],
  data() {
    return {
      modal: {
        visible: false,
      },
      form: {
        file: null,
        image: null,
        progress: {
          loading: false,
          value: 0,
          animated: false,
        },
      }
    };
  },
  methods: {
    modalState(visible) {
      this.modal.visible = visible;
    },
    async submit(e) {
      const formData = new FormData();

      e.preventDefault();
      formData.append('image', this.form.file);
      formData.append('display', 'true');
      this.form.progress.loading = true;
      const image = (await axios.post(`${this.apiBaseUrl}/detector/model/${this.model.id}/detect`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          hashkey: this.apiKey()
        },
        responseType: 'blob',
        onUploadProgress: e => {
          this.form.progress.animated = false;
          this.form.progress.value = Math.floor(e.loaded);
          if (e.loaded >= 100) {
            this.form.progress.animated = true;
          }
        },
      })).data;
      this.form.progress.loading = false;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.form.image = reader.result;
      };
    },
    reset(keepFile) {
      this.form.progress.loading = false;
      this.form.image = null;
      if (!keepFile) {
        this.form.file = null;
      }
    }
  },
}
</script>
