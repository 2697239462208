<template>  
  <b-modal
    ok-only
    size="lg"
    title="加入資料集"
    ok-title="開始上傳"
    id="dataset-modal"
    v-model="modal.visible"
    :busy="modal.uploading"
    :visible="modal.visible"
    :no-close-on-esc="modal.uploading"
    :no-close-on-backdrop="modal.uploading"
    @ok="upload"
    @hidden="reset(false)">
    <b-alert variant="info" show>
      <fa-icon icon="info-circle"></fa-icon>
      正在將資料集加入 <strong>{{ model ? model.name : '' }}</strong>
    </b-alert>
    <b-form @submit="upload">
      <b-form-group
        label="資料集壓縮檔">
        <b-form-file
          v-model="modal.file"
          placeholder=""
          browse-text="選擇檔案"
          drop-placeholder="拖放檔案到這裡"
          required
          @change="reset(true)">
        </b-form-file>
        <div slot="description">
          <p>
            請確保 zip 檔內含有下列項目：
            <br>
            - 影像檔 (建議圖片畫質為 640*640，減少圖片上傳時間)
            <br>
            - 標記檔
          </p>
        </div>
      </b-form-group>
    </b-form>
    <b-progress
      :max="modal.progress.max"
      show-progress
      animated
      v-if="this.modal.uploading">
      <b-progress-bar :value="modal.progress.current">
        {{ (modal.progress.current / modal.progress.max) * 100 }} %
      </b-progress-bar>
    </b-progress>
  </b-modal>
</template>

<script>
import axios from 'axios';
import variableMixin from '../lib/variableMixin';
import generalMixin from '../lib/generalMixin';

export default {
  name: 'DatasetModal',
  props: {
    model: Object,
  },
  mixins: [variableMixin, generalMixin],
  data() {
    return {
      modal: {
        visible: false,
        file: null,
        uploading: false,
        progress: {
          max: 100,
          current: 0
        },
        result: null,
      }
    }
  },
  methods: {
    modalState(state) {
      this.modal.visible = state;
    },
    async upload(e) {
      const formData = new FormData();
      const h = this.$createElement;

      try {
        e.preventDefault();
        this.modal.uploading = true;
        formData.append('zipFile', this.modal.file);
        this.modal.result = (await axios.post(`${this.apiBaseUrl}/datasets/${this.model.id}/upload`, formData, {
          params: { hashkey: this.apiKey() },
          headers: { 'Content-Type': 'multipart/form-data' },
          validateStatus(status) {
            return status >= 200 && status < 300;
          },
          onUploadProgress: e => {
            this.modal.progress.max = 100;
            this.modal.progress.current = Math.round(((e.loaded / e.total) + Number.EPSILON) * 100);
          },
        })).data;

        setTimeout(() => {
          const categories = this.modal.result.classes.map(c => h('li', {}, c));
          const vNodesMsg = h(
            'div',
            {},
            [
              `共上傳 ${this.modal.result.imageCount} 張影像，包括下列種類`,
              h('ul', { style: { 'margin-bottom': 0 } }, categories)
            ]
          );
          this.makeToast({
            title: '完成！',
            content: vNodesMsg,
            variant: 'success',
            noAutoHide: false,
          });
          this.$emit('uploaded', {});
          this.reset();
        }, 800);
      } catch (ex) {
        this.reset();
        if (ex.response && ex.response.status === 400) {
          this.makeToast({
            title: '資料格式有誤！',
            content: '上傳檔案中含有錯誤的資料，請確認無誤後再上傳一次',
            variant: 'danger',
            noAutoHide: false,
          });
          return;
        }
        this.makeToast({
          title: '上傳失敗',
          content: '上傳資料集時發生未知錯誤，請再嘗試一次',
          variant: 'danger',
          noAutoHide: false,
        });
      }
    },
    reset(show) {
      this.modal = {
        visible: show || false,
        file: null,
        uploading: false,
        progress: {
          max: 100,
          current: 0
        },
        result: null,
      };
    },
  },
}
</script>
